let $ = jQuery;

export default class Aspmail {
    constructor() {
        this.locale = null;
        /**
         * @type {object}
         * @property {Object} newsLinkTitle
         * @property {String} newsLinkTitle.show
         * @property {String} newsLinkTitle.hide
         * @property {Object} pgnLabel
         * @property {String} pgnLabel.campaign
         * @property {String} pgnLabel.segment
         * @property {String} pgnLabel.selected
         * @property {String} cookieName
         */
        this.homeActionOption = null;
    }

    static centerBlock($block) {
        let min = $(window).height() - $block.height();
        if (isNaN(min) || min < 40) {
            min = 40;
        }
        $block.css('margin-top', (min / 2 - 10));
    }

    /**
     *
     * @param name
     * @param value
     * @param {Date} expires
     * @param path
     * @param domain
     * @param secure
     */
    static setCookie(name, value, expires, path, domain, secure) {
        document.cookie = name + '=' + encodeURIComponent(value)
            + ((expires) ? '; expires=' + expires.toUTCString() : '')
            + ((path) ? '; path=' + path : '')
            + ((domain) ? '; domain=' + domain : '')
            + ((secure) ? '; secure' : '');
    }

    init() {
        // Chargement translation JS
        $.i18n.properties({
            name: 'messages',
            path: '/build/messages/',
            mode: 'map',
            language: this.getLocale(),
            cache: true
        });

        // Gestion des liens externals
        $('a.external').on('click', function () {
            window.open(this.href);
            return false;
        });

        // Gestion tooltip
        $('[data-toggle="tooltip"]').tooltip();

        // Gestion popover (help et help-big)
        const cHelpClassName = ['help-big', 'help'];
        for (const ix in cHelpClassName) {
            if (cHelpClassName.hasOwnProperty(ix)) {
                this.initPopover(cHelpClassName[ix]);
            }
        }

        $('nav#menu').affix({offset: {top: $('header').outerHeight()}});
    }

    initPopover(className) {
        const $popovers = $(`.${className}[data-toggle="popover"]`);
        $popovers.hover(function () {
            $('span', this).removeClass('text-info');
        }, function () {
            $('span', this).addClass('text-info');
        }).each(function () {
            const placement = (typeof $(this).data('placement') !== 'undefined') ? $(this).data('placement') : 'right';
            $(this).popover({
                html: true,
                delay: {'show': 200, 'hide': 200},
                placement: placement,
                template: `<div class="popover ${className}" role="tooltip">`
                    + `<div class="arrow"></div>`
                    + `<h3 class="popover-title"></h3><span class="close">&times;</span>`
                    + `<div class="popover-content"></div></div>`,
                trigger: 'click focus'
            });
        });
        $(document).on('click', '.popover .close', function() {
            window.aspmail.closePopover(this);
        });
    }

    loginInit() {
        // Focus sur l'identifiant
        document.getElementById('username').focus();
        // Gestion du "centrage" du bloc de connexion
        Aspmail.centerBlock($('#security'));
        let centerTime = null;
        $(window).unbind('resize.eam').bind('resize.eam', function () {
            window.clearTimeout(centerTime);
            centerTime = window.setTimeout(function () {
                Aspmail.centerBlock($('#security'));
            }, 200);
        });
    }

    getLocale() {
        if (this.locale === null) {
            this.locale = $('html').attr('lang');
        }
        return this.locale;
    }

    closePopover(o) {
        const $popover = $(`[aria-describedby="${$(o).parent('.popover').attr('id')}"]`);
        if ($popover.length > 0) {
            $popover.popover('hide');
        }
    }

    waitingMode(isActive) {
        const $html = $('html');
        if (isActive === false) {
            $html.removeClass('waitingMode');
        } else if (false === $html.hasClass('waitingMode')) {
            $html.addClass('waitingMode');
        }
    }

    campaignSegmentDuplicate() {
        // Vérification du nombre de campagne cible existant
        const $modalContainer = $('#segmentDuplicateModal');
        const cTarget = $modalContainer.find('input[type="radio"]');
        if (cTarget.length > 0) {
            $('button.eamSegmentDuplicate').unbind('click.eam').bind('click.eam', function () {
                $modalContainer.modal('show');
                $modalContainer.find('input.duplicateSegmentId').val($(this).data('segmentid'));
            });
        } else {
            $('button.eamSegmentDuplicate').addClass('invisible');
        }
    }

    campaignImageIntegration(o) {
        let imaIntegration = '-1';
        let oUrlSelector;
        try {
            if (o.tagName === 'INPUT') {
                const oName = $(o).attr('name');
                imaIntegration = $(`input[name="${oName}"]:checked`).val();
                oUrlSelector = `input[name="${oName.replace('_integration', '_url')}"]`;
            } else {
                imaIntegration = $('input[type="radio"]:checked', 0).val();
                const strUrlId = $(o).attr('id').replace('_integration', '_url');
                oUrlSelector = '#' + strUrlId;
            }
        } catch (ex) {
            window.alert(ex);
        }

        const displayValue = (imaIntegration === '2' ? 'block' : 'none');
        $(oUrlSelector).attr('style', `display:${displayValue}!important`);
    }

    initModalRecipientEditor(title) {
        const iframeHeight = 450;
        const iframeSrc = '/css/images/ajax-loader.gif';
        let $editModal = $('#recipientEditModal');
        // Mise en place Modal
        if ($editModal.length === 0) {
            $editModal = $(`<div id="recipientEditModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recipientEditModalLabel" aria-hidden="true">`
                + `<div class="modal-dialog modal-lg">`
                + `    <div class="modal-content">`
                + `        <div class="modal-header">`
                + `            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>`
                + `            <h4 class="modal-title" id="recipientEditModalLabel">${title}</h4>`
                + `        </div>`
                + `        <div class="modal-body"><iframe src="${iframeSrc}" width="400" height="${iframeHeight}" frameborder="0" allowfullscreen=""></iframe></div>`
                + `    </div>`
                + `</div></div>`);
            $editModal.appendTo('body');
        }
        const editModalFrame = $editModal.find('iframe');
        // Mise à jour des liens d'édition
        $('a.recipientEditModal').unbind('click.eam').bind('click.eam', function () {
            $editModal.find('.modal-dialog').width($('#content').find('.container').width());
            editModalFrame.width('99%');
            $editModal.modal('show');
            editModalFrame.attr('src', $(this).data('href'));
        });
        // Mise à jour de la hauteur de la frame
        editModalFrame.unbind('load.eam').bind('load.eam', function () {
            const currentHeight = (this.contentWindow.document.body.offsetHeight + 50);
            $(this).height(currentHeight > $(this).height() ? currentHeight : iframeHeight);
            $(this.contentWindow.document.body).css('overflow-x', 'hidden');
        });
        // Fermeture
        $editModal.find('button.close').unbind('click.eam').bind('click.eam', function () {
            editModalFrame.attr('src', iframeSrc);
        });
    }

    isConnected(url) {
        let isConnected = false;
        window.aspmail.waitingMode(true);

        $.ajax({
            type: 'GET', data: {}, async: false, url: url, dataType: 'json', context: this,
        }).done(function () {
            window.aspmail.waitingMode(false);
            isConnected = true;
        }).fail(function () {
            window.aspmail.waitingMode(false);
            $('#eamLoginModal').modal('show');
        });

        return isConnected;
    }

    campaignRecipientPrevious(formElement, isImportAction) {
        if ($(formElement).length === 0) {
            return;
        }
        const formPrevious = $(formElement).parents('form');

        const idSegmentChosen = $('select[data-eam="previousSegment"] option:selected', formPrevious).val();
        if (idSegmentChosen !== undefined && idSegmentChosen !== '' && (false === isNaN(idSegmentChosen))) {

            const ajaxData = {};
            ajaxData['idSegmentChosen'] = idSegmentChosen;

            // Vérification action
            if (isImportAction === true) {
                const $inputAction = $('div[data-eam="previousSegmentFilter"] input:checked');
                if ($inputAction.length > 0) {
                    ajaxData['filterChosen'] = $inputAction.val();
                }
            }

            window.aspmail.waitingMode(true);
            $.ajax({
                type: 'POST', data: ajaxData, async: true, url: formPrevious.attr('action'), dataType: 'json', context: this,
            }).done(function (data) {
                if (data.done === true) {
                    formPrevious.parent().html(data.htmContent);
                }
                window.aspmail.waitingMode(false);
            }).fail(window.aspmail.xhrFailHandler);
        } else {
            window.alert($.i18n.prop('campaign_recipient_import_previous_nosegmentchoosen'));
        }
    }

    homeAction(option) {
        this.homeActionOption = option;

        const $newsContainer = $('#newsDivText');

        $('#eamNewsLink').unbind('click.eam').bind('click.eam', {self: this}, function (event) {
            const isCurrentVisible = ($newsContainer.is(':visible'));
            const self = event.data.self;
            if (isCurrentVisible === false) {
                $newsContainer.show();
                $('#eamNewsLinkFa').prop('class', 'fa fa-times');
                $('#eamNewsLink').prop('title', self.homeActionOption.newsLinkTitle.show);
                Aspmail.setCookie(self.homeActionOption.cookieName, 'show', null, '/');
            } else {
                $newsContainer.hide();
                $('#eamNewsLinkFa').prop('class', 'fa fa-eye');
                $('#eamNewsLink').prop('title', self.homeActionOption.newsLinkTitle.hide);
                Aspmail.setCookie(self.homeActionOption.cookieName, 'hide', null, '/');
            }
        });

        const oHomeLabelType = $('#pgn_filter_field').find('select.eamHomeLabelType');
        oHomeLabelType.find('option').each(function (i, that) {
            $(that).prop('selected', ($(that).val() === this.homeActionOption.pgnLabel.selected));
        }.bind(this));

        $('#pgn_submit_search').unbind('click.title').bind('click.title', {self: this}, function (event) {
            const valueHomeType = oHomeLabelType.val();
            const $paginationResultsContainer = $('#pgn_table_results');
            const self = event.data.self;
            $paginationResultsContainer
                .find('th[rel="name"]')
                .html((valueHomeType === 'segment' ? self.homeActionOption.pgnLabel.segment : self.homeActionOption.pgnLabel.campaign))
                .append($('#sort_name'));

            window.paginationHelper.handlerOrder();
            return false;
        });
    }

    /**
     *
     * @param options
     * @param {string} options.url
     * @param {string} options.text.txtRoutingDone
     * @param {string} options.text.txtRoutingFailed
     * @param {string} options.text.txtSeeMore
     */
    sendSegmentRoutingTest(options) {
        $('#eamRoutingTest').unbind('click.eam').bind('click.eam', function () {
            window.aspmail.waitingMode(true);
            $(this).attr('disabled', 'disabled');
            // Timeout de 500 pour que le cursors wait soit bien affiché
            setTimeout(function () {
                $.ajax({
                    type: 'GET',
                    async: false,
                    url: options.url,
                    dataType: 'json'
                }).done(function (data) {
                    const alertClass = (data.isDone === 1 ? 'alert-warning' : 'alert-danger');
                    const message = (data.isDone === 1 ? options.text.txtRoutingDone : options.text.txtRoutingFailed);
                    const alertContent = $.parseHTML(`<div role="alert" class="alert ${alertClass} alert-dismissible fade in">`
                        + `<button aria-label="Close" data-dismiss="alert" class="close" type="button"><span aria-hidden="true">×</span></button>`
                        + `${message}<br>`
                        + `<button class="btn btn-xs btn-primary" onclick="$(this).parent().find('div.sr-only').removeClass('sr-only');$(this).fadeOut();">`
                        + `<span class="fa fa-plus"></span>&nbsp;${options.text.txtSeeMore}</button>`
                        + `<div class="sr-only"><hr>${data.content}<hr></div></div>`);
                    $(alertContent).hide().insertAfter('#eamRoutingTest').fadeIn();
                }).fail(window.aspmail.xhrFailHandler);
                $('#eamRoutingTest').removeAttr('disabled');
                window.aspmail.waitingMode(false);
            }, 500);
        });
    }

    xhrFailHandler(jqXHR, textStatus) {
        window.aspmail.waitingMode(false);

        let failMessage = '';
        switch (jqXHR.status) {
            case 200:
                // Le retour n'est pas json, la personne n'est pas connecté ?
                $('#eamLoginModal').modal('show');
                break;
            case 403:
                // Le segment n'appartient pas à l'utilisateur en cours
                failMessage = $.i18n.prop('error_403_message');
                break;
            case 404:
                // Le segment n'existe pas
                failMessage = $.i18n.prop('error_404_message');
                break;
            default:
                // erreur inconnu
                failMessage = `${$.i18n.prop('error_default_message')}`
                    + `\nstatus: ${jqXHR.status}`
                    + `\ntext: ${textStatus}`;
        }
        if (failMessage.length) {
            window.alert(failMessage);
        }
    }
}
