/**
 * @name translationOption
 * @property {string} maxResult
 * @property {string} show_more
 * @property {string} search
 * @property {string} empty_result
 */

export default {
    container: null,
    nbElement: 0,
    options: {
        route: '/',
        trans: {
            maxResult: 'Affichage par page',
            show_more: 'Afficher plus de résultats',
            search: 'Rechercher',
            empty_result: 'Aucune campagne',
            needEmail: 'La recherche doit être un courriel',
        },
        pgn_list_disp: [10, 50, 100],
        maxResult: 10,
        isEnd: false,
        field: '',
        order: 'DESC',
        no_pagination: false,
        no_search_engine: false,
        no_order: false
    },
    renderCallBack: [],
    is_search: false,
    field: '',
    order_default: '',
    is_busy: false,
    is_refresh: false,
    is_ajax: false,
    input_email: false,

    /**
     * @param {Object} options
     * @param {Boolean} options.route
     * @param {translationOption} options.trans
     * @param {Array} options.pgn_list_disp
     * @param {Number} options.maxResult
     * @param {Boolean} options.isEnd
     * @param {String} options.field
     * @param {String} options.order
     * @param {Boolean} options.no_pagination
     * @param {Boolean} options.no_search_engine
     * @param {Boolean} options.no_order
     * @param {String} options.startDate
     * @param {String} options.endDate
     * @param {String} options.homeDisplayType
     * @param {String} options.input_email
     */
    init: function (options) {

        $.extend(this.options, options);

        this.startDate = typeof options.startDate !== 'undefined' ? $('#' + options.startDate) : null;
        this.endDate = typeof options.endDate !== 'undefined' ? $('#' + options.endDate) : null;
        this.homeDisplayType = typeof options.homeDisplayType !== 'undefined' ? $('#' + options.homeDisplayType) : null;
        this.input_email = typeof options.input_email !== 'undefined';

        this.is_search = false;
        this.field = this.options.field;

        this.is_ajax = false;
        this.order_default = this.options.order;

        if (false === this.options.no_pagination) {
            this.createEntryLimitSelector();
            if (false === this.options.isEnd) {
                this.createShowMoreButton();
            }
        }
        if (false === this.options.no_search_engine) {
            this.createSearchEngine();
        }
        if (false === this.options.no_order) {
            this.createSortButtons();
            this.highlightActiveSortButton(this.field, this.order_default);
        }

        this.nbElement = this.getContainer().find('tbody tr').length;

        // Traitement eamAjax
        this.addAjaxCallback();
    },

    getContainer: function () {
        if (this.container === null) {
            this.container = $('#pgn_table_results');
        }
        return this.container;
    },

    createEntryLimitSelector: function () {
        if ($('#pgn_select_affichage').length === 0) {
            const options = [];
            for (let i = 0; i < this.options.pgn_list_disp.length; i++) {
                let selected;
                if (parseInt(this.options.pgn_list_disp[i], 10) === parseInt(this.options.maxResult, 10)) {
                    selected = 'selected';
                } else {
                    selected = '';
                }
                options.push(`<option value="${this.options.pgn_list_disp[i]}" ${selected} >${this.options.pgn_list_disp[i]}</option>`);
            }

            this.getContainer().before(`<div id="pgn_select_affichage"><label for="pgn_select_disp">${this.options.trans.maxResult}</label>`
                + `<select id="pgn_select_disp" name="select">${options}</select></div>`);
        }

        $('#pgn_select_disp').unbind('change.eam').bind('change.eam', function () {
            this.reset();
            return false;
        }.bind(this));
    },

    createShowMoreButton: function () {
        let $showMore = $('#pgn_show_more');
        if ($showMore.length === 0) {
            $showMore = $(`<div id="pgn_show_more" ><input type="submit" class="btn btn-primary" value="${this.options.trans.show_more}"></div>`);
            this.getContainer().after($showMore);
        }
        $showMore.unbind('click.eam').bind('click.eam', {self: this}, function (event) {
            event.data.self.showMore();
            return false;
        });
    },

    createSearchEngine: function () {
        if ($('#pgn_filter_field').length === 0) {
            $('#pgn_form_result').before(`<form id="pgn_filter_field" method="POST" action="${this.options.route}" class="form-inline">`
              + `<div class="input-group">`
              + `<input id="pgn_search_field" type="text" class="form-control" placeholder="${this.options.trans.search}...">`
              + `<div class="input-group-btn">`
              + `<button id="pgn_submit_search" class="btn btn-default"><span class="fa fa-search"></span></button>`
              + `</div>`
              + `</div>`
              + `</form>`
              + `<a href="#" id="pgn_export_csv" class="btn btn-secondary pull-right"><i class="fa fa-file-excel"></i> Export (CSV)</a>`);
        }
        $('#pgn_submit_search').unbind('click.eam').bind('click.eam', {self: this}, function (event) {
            const self = event.data.self;
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (self.input_email) {
                const $searchInput = $('#pgn_search_field');
                if (false === re.test(String($searchInput.val()).toLowerCase())) {
                    window.alert(self.options.trans.needEmail);
                    $searchInput.focus().select();
                    return false;
                }
            }

            self.filter();
            return false;
        });

        $('#pgn_export_csv').unbind('click.eam').bind('click.eam', {self: this}, function (event) {
            event.data.self.exportCSV();
            return false;
        });
    },
    exportCSV: function () {
        const exportUrl = this.options.route + '/export';

        // Redirige vers l'URL d'export
         window.location.href = exportUrl;
    },

    createSortButtons: function () {
        this.getContainer().find('th').each(function () {
            if ($(this).text().length > 0 && $(this).attr('rel')) {
                $(this).append(`<span class="fa fa-sort-amount-down" data-pgn-sort="0" id="sort_${$(this).attr('rel').toLocaleLowerCase()}"></span>`);
            }
        });
        this.handlerOrder();
    },

    handlerOrder: function () {
        this.getContainer().find('th span[data-pgn-sort]')
            .unbind('mouseenter.eam, mouseleave.eam, click.eam')
            .bind('mouseenter.eam', function () {
                if ($(this).hasClass('active')) {
                    $(this).addClass('fa-flip-vertical');
                }
            })
            .bind('mouseleave.eam', function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('fa-flip-vertical');
                }
            })
            .bind('click.eam', {self: this}, function (event) {
                const order = ($(this).data('pgn-sort') > 0 ? 'DESC' : 'ASC');
                event.data.self.sort($(this).parent().attr('rel'), order);
                return false;
            });
    },

    highlightActiveSortButton: function (field, order) {
        const thSort = this.getContainer().find('th span#sort_' + field.toLocaleLowerCase());
        if (thSort.length > 0) {
            // Inactivation
            const cPgnSort = this.getContainer().find('th span[data-pgn-sort]');
            cPgnSort.data('pgn-sort', 0);
            cPgnSort.removeClass('active fa-sort-amount-up fa-sort-amount-down fa-flip-vertical').addClass('fa-sort-amount-down');
            // Activation
            thSort.data('pgn-sort', (order.toLocaleLowerCase() === 'desc' ? -1 : 1));
            thSort.addClass('active').addClass(order.toLocaleLowerCase() === 'desc' ? 'fa-sort-amount-down' : 'fa-sort-amount-up');
        }
    },

    reset: function () {
        // Suppression des données
        this.nbElement = 0;
        this.display(this.nbElement, $('#pgn_select_disp').val(), true, this.field, this.order_default);
    },

    refresh: function () {
        // Rafraichissement des données
        this.is_refresh = true;
        const nbElement = this.getContainer().find('tbody tr').length;
        this.display(0, nbElement, true, this.field, this.order_default);
    },

    showMore: function () {
        if (this.getContainer().find('tbody tr.is_end').length === 0) {
            this.display(this.nbElement, $('#pgn_select_disp').val(), false, this.field, this.order_default);
        }
    },

    filter: function () {
        const $emptyContainer = $('#pgn_empty_result');
        if ($emptyContainer.length > 0) {
            $emptyContainer.remove();
        }
        this.nbElement = 0;
        this.is_search = true;
        this.display(this.nbElement, $('#pgn_select_disp').val(), true, this.field, this.order_default);
    },

    sort: function (field, order) {
        if (field !== this.field || order !== this.order_default) {
            this.nbElement = 0;
            this.field = field;
            if (order !== null) {
                this.order_default = order;
            }
            this.highlightActiveSortButton(this.field, this.order_default);

            if (this.options.route === '_') {
                this.orderJS(this.getContainer(), field, order);
            } else {
                this.display(this.nbElement, $('#pgn_select_disp').val(), true, field, this.order_default);
            }
        }
    },

    // Trier les colonnes sans passer par AJAX
    orderJS: function (table, field, order) {
        const index = $(`th[rel='${field}']`, table).index();

        // Trier les lignes
        let rows = table.find('>tbody >tr').toArray().sort(function (a, b) {
            const td1 = $(`td:eq(${index})`, a).html(), td2 = $(`td:eq(${index})`, b).html();
            return $.isNumeric(td1) && $.isNumeric(td1) ? td1 - td2 : td1.localeCompare(td2);
        });
        if (order === 'DESC') {
            rows = rows.reverse();
        }

        // Modifier l'affichage
        for (let i = 0; i < rows.length; i++) {
            table.append(rows[i]);
        }
    },

    display: function (start, maxResult, bln_reset, field, order) {
        if (this.is_busy) {
            return false;
        }

        this.setBusyOn();

        const ajax_url = this.options.route,
            ajax_data = {start: parseInt(start, 10), maxResult: parseInt(maxResult, 10), field: field, order: order};

        if (this.is_search) {
            ajax_data['search'] = $('#pgn_search_field').val();
        }
        if (this.is_refresh) {
            ajax_data['lrRefresh'] = 1;
            this.is_refresh = false;
        }
        if (this.type_search !== null) {
            ajax_data['type_search'] = this.type_search;
        }
        if (this.mode_search !== null) {
            ajax_data['mode_search'] = this.mode_search;
        }
        if (this.startDate !== null) {
            ajax_data['startDate'] = this.startDate.val();
        }
        if (this.endDate !== null) {
            ajax_data['endDate'] = this.endDate.val();
        }
        if (this.homeDisplayType !== null) {
            ajax_data['homeDisplayType'] = this.homeDisplayType.val();
        }

        const self = this;
        $.ajax({
            url: ajax_url,
            type: 'POST',
            data: ajax_data,
            dataType: 'html',
            success: function (msg) {
                if ($('form#_authentication', msg).length > 0) {
                    $('#eamLoginModal').modal('show');
                } else {
                    $('#pgn_select_affichage').show();
                    self.getContainer().show().removeClass('invisible');
                    $('#pgn_form_result').removeClass('invisible');

                    if (bln_reset === true) {
                        self.getContainer().find('tbody tr').remove();
                    }

                    if ((self.is_search || self.is_ajax) && msg.length === 0) {
                        if ($('#pgn_empty_result').length === 0) {
                            $('#pgn_form_result').after(`<div class="alert alert-info" role="alert" id="pgn_empty_result">${self.options.trans.empty_result}</div>`);
                            $('#pgn_select_affichage, #pgn_show_more').hide();
                            self.getContainer().hide();
                        }
                        $('#pgn_show_more').remove();
                    } else {
                        self.getContainer().find('tbody').append(msg);

                        self.nbElement += parseInt(maxResult, 10);
                        if (self.getContainer().find('tbody tr.is_end').length > 0) {
                            $('#pgn_show_more').remove();
                        } else {
                            self.createShowMoreButton();
                        }
                    }
                }

                self.setBusyOff();
            },
            error: function (xhr, status, error) {
                self.setBusyOff();
                try {
                    console.log(xhr, status, error);
                } catch (ex) {
                    // ras
                }
            }
        });

        return false;
    },

    setBusyOn: function () {
        this.is_busy = true;
        $('#pgn_select_affichage, #pgn_show_more').css('opacity', '0.5');
        this.getContainer().css('opacity', '0.5');

        let $loadingContainer = $('#pgn_loading');
        if ($loadingContainer.length === 0) {
            $loadingContainer = $('<div id="pgn_loading"></div>');
            $loadingContainer.appendTo('#pgn_form_result');
        }
        $loadingContainer.width($('#pgn_form_result').width()).show();
    },

    setBusyOff: function () {
        const $loadingContainer = $('#pgn_loading');

        // Activation contenu
        this.is_busy = false;
        $('#pgn_select_affichage, #pgn_show_more').css('opacity', '1');
        this.getContainer().css('opacity', '1');

        if ($loadingContainer.length > 0) {
            $loadingContainer.remove();
        }

        // Traitement eamAjax
        this.addAjaxCallback();

        // Traitement renderCallBack
        if (this.renderCallBack.length > 0) {
            this.renderCallBackAction();
        }
    },

    renderCallBackAction: function () {
        for (const x in this.renderCallBack) {
            if (this.renderCallBack.hasOwnProperty(x)) {
                const strFunction = this.renderCallBack[x];
                if (strFunction && typeof strFunction === 'function') {
                    strFunction();
                }
            }
        }
    },

    /**
     * @public
     * @param {string} strFunction
     */
    addRenderCallBack: function (strFunction) {
        if (strFunction && typeof strFunction === 'function') {
            this.renderCallBack.push(strFunction);
        }
    },


    replaceText: function (replacement) {
        if (replacement && typeof (replacement) === 'object') {
            for (const domId in replacement) {
                if (replacement.hasOwnProperty(domId)) {
                    $('#' + domId).html(replacement[domId]);
                }
            }
        }
    },

    /**
     * @private
     */
    addAjaxCallback: function () {
        this.getContainer().find('a.eamAjax').unbind('click.lh').bind('click.lh', {self: this}, function (event) {
            let proceed = true;
            if ($(this).data('eam-ajax-message')) {
                proceed = window.confirm($(this).data('eam-ajax-message'));
            }
            if (proceed) {
                const href = $(this).attr('href');
                window.aspmail.waitingMode(true);

                const self = event.data.self;
                $.ajax({
                    type: 'GET',
                    async: true,
                    url: href,
                    dataType: 'json'
                }).done(function (data) {
                    if (data.message) {
                        $('#alerte').find('.container').html(`<div class="alert alert-danger alert-error alert-dismissible" role="alert">`
                            + `<button type="button" class="close" data-dismiss="alert">`
                            + `<span aria-hidden="true">&times;</span><span class="sr-only"></span>`
                            + `</button>${data.message}</div>`);
                    }

                    // Remplacement de texte
                    self.replaceText(data.replace);
                    self.is_ajax = true;
                    self.refresh();
                    window.aspmail.waitingMode(false);
                }).fail(function (jqXHR, textStatus) {
                    window.aspmail.waitingMode(false);
                    let failMessage = '';
                    switch (jqXHR.status) {
                        case 200:
                            // Le retour n'est pas json, la personne n'est pas connecté ?
                            $('#eamLoginModal').modal('show');
                            break;
                        case 403:
                            // L'objet n'appartient pas à l'utilisateur en cours
                            failMessage = $.i18n.prop('error_403_message');
                            self.refresh();
                            break;
                        case 404:
                            // L'objet n'existe pas
                            failMessage = $.i18n.prop('error_404_message');
                            self.refresh();
                            break;
                        default:
                            // erreur inconnu
                            failMessage = `${$.i18n.prop('error_default_message')}`
                                + `\nstatus: ${jqXHR.status}`
                                + `\ntext: ${textStatus}`;
                    }
                    if (failMessage.length) {
                        window.alert(failMessage);
                    }
                });
            }
            return false;
        });
    }
};
