export default {
    container: null,
    iFrame: null,
    timerResize: null,
    css: null,
    isDisconnect: false,
    route: {
        viewModel: null,
        editModel: null,
        blockContent: null
    },

    block: {
        current: null,
        currentZone: null,
        insertCache: {},

        showAddForm: function () {
            $('#eamTemplateBlocksPredefined').modal('show');
        },

        insert: function () {
            const blockPlace = $('input[name="app_campaign_model_template_add[blockPlace]"]:checked').val();
            const $blockPredefinedInput = $('input[name="app_campaign_model_template_add[blocksPredefined]"]:checked');
            const blockPredefined = $blockPredefinedInput.val();

            let zoneId = eamTemplate.block.currentZone;
            if (eamTemplate.block.current !== null) {
                zoneId = eamTemplate.block.current.data('zone');
            }
            const now = new Date().getTime();

            // Fermeture Modal
            $('#eamTemplateBlocksPredefined').modal('hide');
            eamTemplate.iframeWaiting(true);

            // Récupération contenu
            let htmContent = '';
            if (blockPredefined === '') {
                htmContent = `<p>${$blockPredefinedInput.parent().text()}</p>`;
            } else {
                // Vérification du cache de donnée
                if (eamTemplate.block.insertCache[blockPredefined] !== undefined) {
                    htmContent = eamTemplate.block.insertCache[blockPredefined];
                } else {
                    $.ajax({
                        type: 'POST',
                        async: false,
                        url: eamTemplate.route.blockContent.replace('__idMbp__', blockPredefined),
                        dataType: 'json'
                    }).done(function (data) {
                        if (data.done === true) {
                            htmContent = data.htmContent;
                        }
                    }).fail(window.aspmail.xhrFailHandler);
                    if (htmContent !== '') {
                        // Enregistrement en cache
                        eamTemplate.block.insertCache[blockPredefined] = htmContent;
                    }
                }
            }
            // Création du block
            const blockContainer = $.parseHTML(`<section class="eamTemplateBlock" data-theme="classic" data-zone="${zoneId}" data-block="new_${now}">${htmContent}</section>`);
            if (eamTemplate.block.current !== null) {
                if (parseInt(blockPlace, 10) === 1) {
                    $(blockContainer).insertAfter(eamTemplate.block.current);
                } else {
                    $(blockContainer).insertBefore(eamTemplate.block.current);
                }
            } else {
                $(`section.eamTemplateZone[data-zone="${zoneId}"]`, eamTemplate.iFrame.contents()).prepend($(blockContainer));
            }
            // Prise en compte TinyMCE
            document.getElementsByTagName('IFRAME')[0].contentWindow.initTinyMCE();
            $('section.eamTemplateBlock', $('iframe').contents()).css('visibility', 'visible');

            window.setTimeout(function() {
                $('section.eamTemplateBlock', $('iframe').contents()).css('visibility', 'visible');
            }, 200);

            // Initialisation edition des blocks
            eamTemplate.block.initEditionMode();
            eamTemplate.iframeWaiting(false);
        },

        initEditionMode: function () {
            const iframeContent = eamTemplate.iFrame.contents();

            // Ajout CSS Rules
            if (eamTemplate.css !== null && $('link[data-eamTemplate="true"]', iframeContent).length === 0) {
                $('html > head', iframeContent).append(`<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css">`
                    + `<link href="${eamTemplate.css}" type="text/css" rel="stylesheet" data-eamTemplate="true">`);
            }

            const $block = $('section.eamTemplateBlock', iframeContent);
            if ($block.length > 0) {
                let needTopPadding = false;
                $block.each(function () {
                    if ($(this).offset().top <= 100) {
                        needTopPadding = true;
                    }
                });
                if (needTopPadding) {
                    $('body', iframeContent).css('padding', '100px 0px');
                }
                // Block
                $block.unbind('mouseenter.eam').bind('mouseenter.eam', function () {
                    eamTemplate.block.current = $(this);
                    eamTemplate.block.toolbar.show();
                    $(this).addClass('active');
                });
                $block.unbind('mouseleave.eam').bind('mouseleave.eam', function () {
                    $(this).removeClass('active');
                    eamTemplate.block.timerClose = window.setTimeout(function () {
                        eamTemplate.block.toolbar.container.fadeOut();
                    }, 2000);
                });
            }

            // Mode edition
            if ($('section.eamTemplateZone', iframeContent).length > 0) {
                // Initialisation des actions sur les blocs
                eamTemplate.block.toolbar.init();
            }
        },

        reorder: function () {
            const iframeContent = eamTemplate.iFrame.contents();
            $('section.eamTemplateNew', iframeContent).remove();

            // Définition de l'ordonnancement et block ajout
            $('section.eamTemplateZone', iframeContent).each(function (zoneIndex, zoneElement) {

                const $block = $('section.eamTemplateBlock', $(this));
                if ($block.length === 0) {
                    const htmlNewBlock = `<section class="eamTemplateNew"><button data-zone="${$(zoneElement).data('zone')}" title="${$.i18n.prop('eamTemplate_block_add')}"><i class="fa fa-plus"></i></button></section>`;
                    $(zoneElement).prepend($.parseHTML(htmlNewBlock));
                    $('section.eamTemplateNew button', zoneElement).unbind('click.eam').bind('click.eam', function () {
                        eamTemplate.block.current = null;
                        eamTemplate.block.currentZone = $(this).data('zone');
                        eamTemplate.block.showAddForm();
                    });
                } else {
                    const indexLast = $block.length - 1;
                    $block.each(function (index, element) {
                        $(element).data('index', index);
                        $(element).data('first', (index === 0));
                        $(element).data('last', (index === indexLast));
                    });
                }
            });

            // Taille Iframe
            eamTemplate.iframeHeight();
        },

        toolbar: {
            timerClose: null,
            container: null,
            up: null,
            down: null,

            init: function () {
                // Définition de l'ordre des blocs
                eamTemplate.block.reorder();

                const container = $.parseHTML(`<div class="eamTemplateToolbar">`
                    + `<button data-action="up" title="${$.i18n.prop('eamTemplate_block_up')}"><i class="fa fa-chevron-up"></i></button>`
                    + `<button data-action="down" title="${$.i18n.prop('eamTemplate_block_down')}"><i class="fa fa-chevron-down"></i></button>`
                    + `<button data-action="remove" title="${$.i18n.prop('eamTemplate_block_remove')}"><i class="fa fa-times"></i></button>`
                    + `<button data-action="add" title="${$.i18n.prop('eamTemplate_block_add')}"><i class="fa fa-plus"></i></button></div>`);
                $('button', container).unbind('click.eam').bind('click.eam', function () {
                    eamTemplate.block.toolbar.run($(this).data('action'));
                });
                $(container).unbind('mouseenter.eam').bind('mouseenter.eam', function () {
                    if (eamTemplate.block.current !== null) {
                        eamTemplate.block.current.addClass('active');
                    }
                    window.clearTimeout(eamTemplate.block.timerClose);
                });
                $(container).unbind('mouseleave.eam').bind('mouseleave.eam', function () {
                    if (eamTemplate.block.current !== null) {
                        eamTemplate.block.current.removeClass('active');
                    }
                    eamTemplate.block.timerClose = window.setTimeout(function () {
                        eamTemplate.block.toolbar.container.fadeOut();
                    }, 2000);
                });

                eamTemplate.block.toolbar.up = $('button[data-action="up"]', container);
                eamTemplate.block.toolbar.down = $('button[data-action="down"]', container);
                eamTemplate.block.toolbar.container = $(container);
                $('body', eamTemplate.iFrame.contents()).append(eamTemplate.block.toolbar.container);
                eamTemplate.block.toolbar.container.fadeOut();
            },

            hide: function () {
                eamTemplate.block.toolbar.container.fadeOut();
                eamTemplate.block.reorder();
            },

            show: function () {
                if (eamTemplate.block.current !== null) {
                    window.clearTimeout(eamTemplate.block.timerClose);
                    eamTemplate.block.toolbar.up.hide();
                    eamTemplate.block.toolbar.down.hide();
                    if (false === eamTemplate.block.current.data('first')) {
                        eamTemplate.block.toolbar.up.show();
                    }
                    if (false === eamTemplate.block.current.data('last')) {
                        eamTemplate.block.toolbar.down.show();
                    }
                    eamTemplate.block.toolbar.container.fadeIn().css({
                        'position': 'absolute',
                        'top': eamTemplate.block.current.position().top - eamTemplate.block.toolbar.container.height(),
                        'left': eamTemplate.block.current.position().left
                    });
                }
            },

            run: function (action) {
                if (eamTemplate.block.current !== null) {
                    const zoneId = eamTemplate.block.current.data('zone');
                    const index = eamTemplate.block.current.data('index');
                    const isFirst = eamTemplate.block.current.data('first');
                    const isLast = eamTemplate.block.current.data('last');

                    if (action === 'add') {
                        eamTemplate.block.showAddForm();
                    } else if (action === 'remove') {
                        if (window.confirm($.i18n.prop('eamTemplate_block_remove_confirm'))) {
                            // Remove tinymce
                            const iframe = document.getElementsByTagName('IFRAME')[0].contentWindow;
                            const tinyMceEditor = iframe.tinymce.editors[eamTemplate.block.current.attr('id')];
                            if (tinyMceEditor) {
                                tinyMceEditor.destroy();
                            }
                            // Remove bloc
                            eamTemplate.block.current.remove();
                        }
                    } else if ((action === 'down' && isLast === false) || (action === 'up' && isFirst === false)) {
                        const $block = $(`section.eamTemplateBlock[data-zone="${zoneId}"]`, eamTemplate.iFrame.contents());
                        if (action === 'up') {
                            $block.eq(index - 1).insertAfter(eamTemplate.block.current);
                        } else if (action === 'down') {
                            eamTemplate.block.current.insertAfter($block.eq(index + 1));
                        }
                    }
                    eamTemplate.block.toolbar.hide();
                }
            }
        }
    },

    init: function (option) {
        eamTemplate.route.viewModel = option.route.viewModel;
        eamTemplate.route.editModel = option.route.editModel;
        eamTemplate.route.testModel = option.route.testModel;
        eamTemplate.route.blockContent = option.route.blockContent;
        eamTemplate.css = option.css;
        eamTemplate.isEditModel = Boolean(option.isEditModel);

        // IFRAME
        eamTemplate.container = $('.eamTemplate');
        eamTemplate.iFrame = eamTemplate.container.find('iframe');
        eamTemplate.iFrame.unbind('load.eam').bind('load.eam', function () {
            // Gestion du mode édition
            eamTemplate.block.initEditionMode();
            // Affichage contenu
            eamTemplate.iframeWaiting(false);
            eamTemplate.iframeHeight();
        });

        // Gestion des action
        const oBtnEdit = eamTemplate.container.find('.eamTemplateEdit');
        const oBtnTest = eamTemplate.container.find('.eamTemplateTest');
        const oBtnSave = eamTemplate.container.find('.eamTemplateSave');
        const oBtnClose = eamTemplate.container.find('.eamTemplateClose');

        // Edit button
        oBtnEdit.show().removeClass('hide');
        oBtnEdit.unbind('click.eam').bind('click.eam', function () {
            eamTemplate.iframeWaiting(true);
            eamTemplate.iFrame.attr('src', eamTemplate.route.editModel);
            oBtnEdit.fadeOut(200, function () {
                oBtnTest.fadeOut();
                oBtnClose.fadeIn();
                oBtnSave.fadeIn();
                eamTemplate.container.addClass('eamTemplateWide');
            });
        });

        // Test button
        oBtnTest.show().removeClass('hide');
        oBtnTest.unbind('click.eam').bind('click.eam', function () {
            window.aspmail.waitingMode(true);
            $(this).attr('disabled', 'disabled');
            // Timer de 500 pour que le cursors wait soit bien affiché
            setTimeout(function () {
                $.ajax({
                    type: 'GET',
                    async: false,
                    url: eamTemplate.route.testModel,
                    dataType: 'json'
                }).done(function (data) {
                    const successMessage = (data.isDone === 1 ? $.i18n.prop('eamTemplate_routing_done') : $.i18n.prop('eamTemplate_routing_failed'));
                    const alertClass = (data.isDone === 1 ? 'alert-warning' : 'alert-danger');
                    const alertContent = $.parseHTML(`<div role="alert" class="alert ${alertClass} alert-dismissible fade in">`
                        + `<button aria-label="Close" data-dismiss="alert" class="close" type="button"><span aria-hidden="true">×</span></button>`
                        + `${successMessage}<br>`
                        + `<button class="btn btn-xs btn-primary" onclick="$(this).parent().find('div.sr-only').removeClass('sr-only');$(this).fadeOut();">`
                        + `<span class="fa fa-plus"></span>&nbsp;${$.i18n.prop('eamTemplate_see_more')}`
                        + `</button>`
                        + `<div class="sr-only"><hr>${data.content}<hr></div></div>`);
                    $(alertContent).hide().appendTo('.eamTemplateAction').fadeIn();
                }).fail(window.aspmail.xhrFailHandler);
                oBtnTest.removeAttr('disabled');
                window.aspmail.waitingMode(false);
            }, 500);
        });

        if (eamTemplate.isEditModel === true) {
            eamTemplate.container.find('.eamTemplateEdit').trigger('click.eam');
        }

        // Close button
        oBtnClose.hide().removeClass('hide');
        oBtnClose.unbind('click.eam').bind('click.eam', function () {
            if (window.confirm($.i18n.prop('eamTemplate_save'))) {
                oBtnSave.trigger('click.eam');
            } else {
                eamTemplate.isDisconnect = false;
            }

            if (false === eamTemplate.isDisconnect) {
                eamTemplate.iframeWaiting(true);
                eamTemplate.iFrame.attr('src', eamTemplate.route.viewModel);
                oBtnSave.fadeOut(200);
                oBtnClose.fadeOut(200, function () {
                    oBtnEdit.fadeIn();
                    oBtnTest.fadeIn();
                    eamTemplate.container.removeClass('eamTemplateWide');
                });
            }
        });

        // Save button
        oBtnSave.hide().removeClass('hide');
        oBtnSave.unbind('click.eam').bind('click.eam', function () {
            const iFrame = document.getElementsByTagName('IFRAME')[0].contentWindow;
            eamTemplate.iframeWaiting(true);
            eamTemplate.block.reorder();
            const ajaxData = {};
            let hasData = false;
            if (iFrame.hasOwnProperty('tinymce') && iFrame.tinymce.hasOwnProperty('editors')) {
                for (const edId in iFrame.tinymce.editors) {
                    if (iFrame.tinymce.editors.hasOwnProperty(edId) && edId.match(/mce_[0-9]+/)) {
                        const currentEditor = iFrame.tinymce.editors[edId];
                        currentEditor.save();
                        const zoneId = $(currentEditor.getBody()).data('zone');
                        const blockId = $(currentEditor.getBody()).data('block');
                        const index = $(currentEditor.getBody()).data('index');
                        const htmContent = currentEditor.getContent({
                            format: 'html'
                        });
                        ajaxData[blockId] = {
                            'blockId': blockId,
                            'zoneId': zoneId,
                            'order': index,
                            'htmContent': htmContent
                        };

                        hasData = true;
                    }
                }
            }
            if (false === hasData) {
                ajaxData['deleteAll'] = true;
            }
            $.ajax({
                type: 'POST',
                async: false,
                url: eamTemplate.route.viewModel,
                data: ajaxData,
                dataType: 'json'
            }).done(function () {
                eamTemplate.isDisconnect = false;
            }).fail(function (jqXHR, textStatus) {
                let failMessage = '';
                switch (jqXHR.status) {
                    case 200:
                        eamTemplate.isDisconnect = true;
                        // Le retour n'est pas json, la personne n'est pas connecté ?
                        $('#eamLoginModal').modal('show');
                        break;
                    case 403:
                        // Le block prédéfini n'appartient pas au modèle en cours
                        // Le modèle n'appartient pas à l'utilisateur en cours
                        failMessage = $.i18n.prop('error_403_message');
                        break;
                    case 404:
                        // Le block prédéfini ou le modèle n'existe pas
                        failMessage = $.i18n.prop('error_404_message');
                        break;
                    default:
                        // erreur inconnu
                        failMessage = `${$.i18n.prop('error_default_message')}`
                            + `\nstatus: ${jqXHR.status}`
                            + `\ntext: ${textStatus}`;
                }
                if (failMessage.length) {
                    window.alert(failMessage);
                }
            });
            eamTemplate.iframeWaiting(false);
        });

        // Affichage contenu
        eamTemplate.iframeHeight();
    },

    iframeWaiting: function (needLock) {
        const iframeContainer = $(eamTemplate.iFrame).parent('div.eamTemplateIframeContainer');
        iframeContainer.removeClass('waiting');
        if (needLock) {
            iframeContainer.addClass('waiting');
        }
    },

    iframeHeight: function () {
        const minHeight = 600;
        const iframeContent = eamTemplate.iFrame.contents();
        let newHeight = minHeight;

        const maxHeight = $(window).height();
        if (maxHeight > minHeight) {
            const contentHeight = iframeContent.height() + 50;
            if (contentHeight > minHeight) {
                if ($('section.eamTemplateZone', iframeContent).length > 0) {
                    // Mode edition
                    newHeight = (contentHeight > maxHeight ? maxHeight : contentHeight);
                } else {
                    newHeight = contentHeight;
                }
            }
        }

        // En mode édition, on laisse de la place en hauteur pour la toolbar et les boutons
        if ($('section.eamTemplateZone', iframeContent).length > 0) {
            iframeContent.find('body').attr('style', 'margin-top:18px !important');
        }

        eamTemplate.iFrame.height(newHeight > minHeight ? newHeight : minHeight);
    }
};
